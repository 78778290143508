@import '../../scss/common';

.RefPage {
  > header {
    display: flex;
    position: fixed;
    top: 0;
    width: 100vw;
    background: $white;
    min-height: 40px;
  }

  .btn {
    @extend %button;
  }
}
