@import '../../scss/common';

.CharGrid {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .row {
    flex: 1;
    display: flex;
  }

  .cell {
    flex: 1;
  }
  button.cell {
    @extend %jpn;
    border: 5px solid $white;
    background: $white;
    font-size: 5vh;
    height: 100%;
    line-height: 100%;

    &:hover {
      border-color: $light-grey;
    }
  }

  button.cell-highlight {
    border-color: red;
    &:hover {
      border-color: red;
    }
  }

  sub {
    display: block;
    font-size: 16px;
  }
}
