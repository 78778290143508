//colours
$light-grey: #ddd;
$dark-grey: #888;
$white: #fff;
$red: #f00;
$green: rgb(0, 184, 0);

//vars
$margin: 20px;
$font-size-base: 20px;
$font-size-large: 40px;
$font-size-giant: 60px;
$font-family-eng: Arial, Helvetica, sans-serif;
$font-family-jpn: serif;
