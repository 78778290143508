@import '../../scss/common';

.MenuPage {
  .title {
    margin: 0;
    font-size: $font-size-giant;
  }
  .desc {
    font-size: $font-size-base;
  }
  .gameBtn {
    @extend %button;
    font-size: $font-size-large;
    margin-top: $margin;
    width: 100%;
  }

  hr {
    margin: 30px 0;
  }
}
