@import '../../scss/common';

%headerNum {
  padding: 0 20px;
  line-height: 150%;
}

.GamePage {
  > header {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background: $white;
  }

  .backBtn {
    @extend %button;
    margin-right: $margin;
  }

  .numWrong {
    @extend %headerNum;
    color: $red;
  }

  .numRight {
    @extend %headerNum;
    color: $green;
  }

  .question {
    border: 5px solid $light-grey;
    font-size: $font-size-large;
    text-align: center;
    flex: 1;
  }
}
