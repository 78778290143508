* {
  box-sizing: border-box;
  outline: none; // TODO: accessibility
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-eng;
}

button {
  padding: 0; //fix: chrome mobile
}

.eng {
  @extend %eng;
}

.jpn {
  @extend %jpn;
}
