%button {
  background: $light-grey;
  border: 0;
  font-size: $font-size-base;
  display: block;
  margin-top: 0px;
  line-height: 150%;
  padding: 0 0.75em;

  &:hover {
    background: $dark-grey;
  }
}

%eng {
  font-family: $font-family-eng;
  font-weight: normal;
}

%jpn {
  font-family: $font-family-jpn;
  font-weight: bold;
}
